import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Abandonner le support d’IE8",
  "description": "Cet article revient sur la présence de plus en plus faible d’Internet Explorer 8 dans le paysage web ainsi que ses implications pour nous, webdesigners.",
  "date": "2014-06-20T00:00:00.000Z",
  "path": "/articles/abandonner-le-support-d-ie8/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`La décision de Microsoft `}<a parentName="p" {...{
        "href": "http://windows.microsoft.com/fr-fr/windows/end-support-help"
      }}>{`de ne plus assurer le support d’XP`}</a>{` a eu pour conséquence de sonner le glas définitif d’Internet Explorer 8. En effet, sans mises à jour, il deviendra de plus en plus exposé à d’éventuels virus ou failles, ce qui forcera ses utilisateurs à migrer vers d’autres navigateurs. Pour nous, webdesigners et développeurs, c’est un vrai soulagement et surtout un gros pas en avant…`}</p>
    <h2>{`En intro : quelques statistiques`}</h2>
    <p>{`Concrètement, si on décide d’arrêter tout support pour IE8, on se prive en moyenne de `}<a parentName="p" {...{
        "href": "http://gs.statcounter.com/#desktop-browser_version_partially_combined-FR-monthly-201404-201406-bar"
      }}>{`3% de nos visiteurs`}</a>{` (données France). Un chiffre que je considère comme raisonnable, surtout si l’on garde en tête que l’on peut décider de proposer à nos visiteurs des navigateurs plus fiables pour mieux protéger leur machine.`}</p>
    <h2>{`Ce que ça implique`}</h2>
    <p>{`En faisant le choix d’exclure IE8 de la liste des navigateurs supportés, de nombreux avantages immédiats vont pouvoir s’offrir à nous; en voici les principaux à mes yeux.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Eléments HTML5 :`}</strong>{` on peut mettre de côté les `}<a parentName="li" {...{
          "href": "https://code.google.com/p/html5shiv/"
        }}>{`shiv HTML5`}</a>{` puisque ces éléments sont supportés nativement par IE9. Les navigateurs gèrent également très bien les éléments `}<code parentName="li" {...{
          "className": "text"
        }}>{`<audio>`}</code>{` et `}<code parentName="li" {...{
          "className": "text"
        }}>{`<video>`}</code>{` par eux-même, plus besoin de plugins flash !`}</li>
      <li parentName="ul"><strong parentName="li">{`JavaScript :`}</strong>{` manipuler le DOM devient plus facile grâce à `}<code parentName="li" {...{
          "className": "text"
        }}>{`getElementsByClassName`}</code>{` et `}<code parentName="li" {...{
          "className": "text"
        }}>{`addEventListener`}</code>{` entre autres. ECMAScript 5, qui introduit de nouvelles méthodes, bénéficie d’un meilleur support.`}</li>
      <li parentName="ul"><strong parentName="li">{`Un meilleur support de CSS3 :`}</strong>{` Plus de possibilités en termes de layouts : transformations, utilisation de `}<code parentName="li" {...{
          "className": "text"
        }}>{`calc`}</code>{`. D’autres propriétés et pseudo-classes utilisables sans risques : `}<code parentName="li" {...{
          "className": "text"
        }}>{`opacity`}</code>{`, `}<code parentName="li" {...{
          "className": "text"
        }}>{`nth-child`}</code>{`, `}<code parentName="li" {...{
          "className": "text"
        }}>{`last-child`}</code>{`… Enfin, On peut abandonner l’utilisation des `}<code parentName="li" {...{
          "className": "text"
        }}>{`em`}</code>{` au profit des `}<code parentName="li" {...{
          "className": "text"
        }}>{`rem`}</code>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Responsive design :`}</strong>{` finies les prises de tête pour `}<a parentName="li" {...{
          "href": "/articles/les-anciens-navigateurs/"
        }}>{`décider comment gérer`}</a>{` les media queries, on peut maintenant faire de l’intégration mobile-first sans aucun risque.`}</li>
      <li parentName="ul"><strong parentName="li">{`SVG :`}</strong>{` le format vectoriel du SVG en fait un format parfait pour les écrans HD et Retina. Plus besoin de prévoir des fallbacks en PNG !`}</li>
    </ul>
    <h2>{`Comment gérer nos visiteurs sur IE8 ?`}</h2>
    <p>{`Faire le choix d’utiliser les features citées ci-dessus, c’est décider d’abandonner tout support d’IE8 par opposé à une simple dégradation de l’expérience. Les avocats de l’accessibilité pourraient sans doute y trouver à redire, mais je pars du principe qu’il faut parfois forcer la main des utilisateurs pour assurer leur sécurité.`}</p>
    <p>{`A mes yeux, la méthode la plus "propre" pour ce faire est de détecter IE8 (via JS ou par un commentaire conditionnel) et d’appliquer un overlay couvrant la totalité de l’écran, informant le visiteur des raisons de ce non-support et proposant une liste de navigateurs plus fiables et sécurisés.`}</p>
    <h2>{`Le mot de la fin`}</h2>
    <p>{`Pour conclure sur une petite touche ironique, sachez que mon site supporte malgré tout IE8 :) Mais il y a de fortes chances pour que ça ne soit plus le cas si je décide de le refondre un de ces jours… et je vous conseille d’en faire autant.`}</p>
    <p className="info">
  Mise à jour (17/10/2015) : depuis j’ai non seulement abandonné le support
  d’IE8, mais aussi celui d’IE9…
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      